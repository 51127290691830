<template>
  <footer class="footer">
    <TheLogo />
    <div class="footer__inner">
      <address>Via Cappuccini 30, 12023, <strong>Caraglio</strong> - CN</address>
      <p>Telefono: +39 0171 817546 - Cellulare: +39 328 4882278 - E-mail: info@control-c.it</p>
      <p>P.IVA: 03863570044</p>
    </div>
  </footer>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.footer {
  text-align: center;
  border-top: solid 3px color('main-700');
  padding: 1rem 0;
  // margin-top: 2rem;
  font-size: 0.8em;
  font-weight: 100;

  p {
    margin: 0;
  }

  .logo {
    max-width: 200px;
  }

  &__inner {
    @include container;
  }
}
</style>
